import React from 'react';
import './footer.css';
import gpt4logo from '../../assets/logo.png';

const Footer = () => {
  return (
    <div className='gpt4__footer section__padding' id="footer">
      <div className='gpt4__footer-heading'>
        <h1 className='gradient__text'>Step into the future before others</h1>
      </div>
      <div className='gpt4__footer-btn'>
        <p>Request Early Access</p>
      </div>
      <div className='gpt4__footer-links'>
        <div className='gpt4__footer-links__logo'>
          <img src={gpt4logo} alt="logo" />
          <p>All Rights Reserved</p>
        </div>
        <div className='gpt4__footer-links__div'>
          <h4>Links</h4>
          <p>Overons</p>
          <p>Social Media</p>
          <p>Counters</p>
          <p>Contacts</p>
        </div>
        <div className='gpt4__footer-links__div'>
          <h4>Company</h4>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Contacts</p>
        </div>
        <div className='gpt4__footer-links__div'>
          <h4>Get in touch</h4>
          <p>854 4554 4444</p>
          <p>info@gpt4.com</p>
        </div>
      </div>
      <div className='gpt4__footer-copyright'>
        <p>© 2023 GPT-4. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer
