import React from 'react'
import './blog.css';
import { Article } from '../../components';
import { blog01, blog02, blog03, blog04, blog05} from './import';

const mainBlogData = {
  imgUrl: blog01,
  imgSource: 'https://www.freepik.com/free-photo/ai-nuclear-energy-future-innovation-disruptive-technology_17850508.htm#&position=15&from_view=undefined',
  author: 'rawpixel.com on Freepik',
  date: 'April 25, 2023',
  title: 'ChatGPT: Everything you need to know about the AI-powered chatbot',
  linkUrl: 'https://techcrunch.com/2023/04/25/chatgpt-everything-you-need-to-know-about-the-ai-powered-chatbot/'
};

const blogData = [
  {
    imgUrl: blog02,
    imgSource: 'https://www.freepik.com/free-photo/man-wearing-smart-glasses-touching-virtual-screen-futuristic-technology-digital-remix_15606963.htm#&position=9&from_view=undefined',
    author: 'rawpixel.com on Freepik',
    date: 'April 06, 2023',
    title: 'GPT-4: How to use, new features, availability, and more',
    linkUrl: 'https://www.digitaltrends.com/computing/chatgpt-4-everything-we-know-so-far/'
  },
  {
    imgUrl: blog03,
    imgSource: 'https://www.freepik.com/free-photo/female-programmer-scanning-her-face-with-biometric-security-technology-virtual-screen-digital-remix_15667458.htm#&position=1&from_view=undefined',
    author: 'rawpixel.com on Freepik',
    date: 'March 22, 2023',
    title: 'Is GPT-4 the dawn of true artificial intelligence?',
    linkUrl: 'https://www.googleadservices.com/pagead/aclk?sa=L&ai=DChcSEwi-2I-fkMr-AhVmmWYCHaFtBhcYABABGgJzbQ&ohost=www.google.com&cid=CAESbeD2h9PheTcuDu9pJZCDkMnD0Yzqt80bQEzHR_kyjcMamaqQFLxmfkEr76SjnIrMT-odIRXLbK36uAzcvQiJWuTOfb8MuzyFL5HIW6d-l2fFN0MCPdbSpVNABVZPGqF_s3QJH45Gja9VmQhItVQ&sig=AOD64_0llgkJCj4evvO3Xrvg6lDvN0FxCw&q&adurl&ved=2ahUKEwiDvYefkMr-AhVs-zgGHTi4A_84ChDRDHoECAcQAQ'
  },
  {
    imgUrl: blog04,
    imgSource: 'https://www.freepik.com/free-photo/cloud-technology-with-futuristic-hologram-smartwatch_17121696.htm#&position=5&from_view=undefined',
    author: 'rawpixel.com on Freepik',
    date: 'March 16, 2023',
    title: "5 jaw-dropping things GPT-4 can do that ChatGPT couldn't",
    linkUrl: 'https://www.cnn.com/2023/03/16/tech/gpt-4-use-cases/index.html'
  },
  {
    imgUrl: blog05,
    imgSource: 'https://www.freepik.com/free-photo/robot-handshake-human-background-futuristic-digital-age_17850426.htm#&position=14&from_view=collections',
    author: 'rawpixel.com on Freepik',
    date: 'March 13, 2023',
    title: 'OpenAI says new model GPT-4 is more creative and less likely to invent facts',
    linkUrl: 'https://www.theguardian.com/technology/2023/mar/14/chat-gpt-4-new-model'
  },
];

const Blog = () => {
  return (
    <div className='gpt4__blog section__padding' id="blog">
      <div className='gpt4__blog-heading'>
        <h1 className='gradient__text'>A lot is happening, 
We are blogging about it.</h1>
      </div>
      <div className='gpt4__blog-container'>
        <div className='gpt4__blog-container__groupA'>
          <Article imgUrl={mainBlogData.imgUrl} imgSource={mainBlogData.imgSource} author={mainBlogData.author} date={mainBlogData.date} title={mainBlogData.title} linkUrl={mainBlogData.linkUrl}/>
        </div>
        <div className='gpt4__blog-container__groupB'>
          {
            blogData.map((data, index) => <Article imgUrl={data.imgUrl} imgSource={data.imgSource} author={data.author} date={data.date} title={data.title} linkUrl={data.linkUrl} key={data.title + index} />)
          }
          {/* <Article imgUrl={blog02} date="Sep 25, 2022" title="GPT-3 and Open  AI is the future. Let us exlore how it is?"/>
          <Article imgUrl={blog03} date="Sep 25, 2022" title="GPT-3 and Open  AI is the future. Let us exlore how it is?"/>
          <Article imgUrl={blog04} date="Sep 25, 2022" title="GPT-3 and Open  AI is the future. Let us exlore how it is?"/>
          <Article imgUrl={blog05} date="Sep 25, 2022" title="GPT-3 and Open  AI is the future. Let us exlore how it is?"/> */}
        </div>
      </div>
    </div>
  )
}

export default Blog
