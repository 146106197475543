import React, { useState } from 'react';
import './navbar.css';
import logo from '../../assets/logo.png';
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';

const Menu = () => {
  return (<>
    <p><a href='#home'>Home</a></p>
    <p><a href='#wgpt4'>What is GPT-4?</a></p>
    <p><a href='#possibility'>Open AI</a></p>
    <p><a href='#features'>Case Studies</a></p>
    <p><a href='#blog'>Library</a></p>
  </>)
}

// BEM -> Block Element Modifier
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='gpt4__navbar'>
      <div className='gpt4__navbar-links'>
        <div className='gpt4__navbar-links_logo'>
          <img src={logo} alt="logo" />
        </div>
        <div className='gpt4__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='gpt4__navbar-sign'>
          <p><a href='https://chat.openai.com/' target="_blank" rel="noreferrer" >Sign in</a></p>
          <button type="button"><a href='https://chat.openai.com/' target="_blank" rel="noreferrer" >Sign up</a></button>
        </div>
        <div className='gpt4__navbar-menu'>
          {toggleMenu
            ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false) } /> 
            : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true) } />   
          }
          {toggleMenu && (
            <div className='gpt4__navbar-menu_container scale-up-center'>
              <div className="gpt4__navbar-menu_container-links">
              <Menu />
              <div className='gpt4__navbar-menu_container-link-sign'>
                <p><a href='https://chat.openai.com/' target="_blank" rel="noreferrer" >Sign in</a></p>
                <button type="button"><a href='https://chat.openai.com/' target="_blank" rel="noreferrer" >Sign up</a></button>
              </div>
              </div>
            </div>
          )

          }
        </div>
    </div>
  )
}

export default Navbar;