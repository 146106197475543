import React from 'react'
import './article.css';

const Article = ({imgUrl, imgSource, author, date, title, linkUrl}) => {
  return (
    <div className='gpt4__blog-container__article'>
      <div className='gpt4__blog-container__article-image'>
        <img src={imgUrl} alt="blogimage" />
        <p><a href={imgSource} rel="noreferrer" target='_blank'>Image by {author}</a></p>
      </div>
      <div className="gpt4__blog-container__article-content">
        <p>{date}</p>
        <h3>{title}</h3>
        <p><a href={linkUrl} rel="noreferrer" target='_blank'>Read Full Article</a></p>
      </div>
    </div>
  )
}

export default Article;