import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => {
  return (
    <div className='gpt4__header section__padding' id="home">
      <div className='gpt4__header-content'>
        <h1 className='gradient__text'>Let's Build Something amazing with GPT-4 OpenAI</h1>
        <p>Don't settle for a basic language model. Upgrade to GPT-4, the powerful AI developed by OpenAI. With its advanced capabilities, GPT-4 can do more than just answer questions. It can generate text, engage in conversations, learn from users, and personalize responses. Experience the full potential of advanced language processing technology with GPT-4.</p>
        <div className='gpt4__header-content__input'>
          <input type="email" placeholder='Your Email Address' />
          <button type="button" ><a href='https://chat.openai.com/' target="_blank" rel="noreferrer" >Get Started</a></button>
        </div>
        <div className='gpt4__header-content__people'>
          <img src={people} alt="people" />
          <p>1,600 people requested access a visit in last 24 hours</p>
        </div>
      </div>
      <div className='gpt4__header-image'>
          <img src={ai} alt="ai" />
          <p><a href="https://www.freepik.com/free-photo/3d-render-technology-background-with-code-male-head_1161595.htm#&position=9&from_view=undefined" rel="noreferrer" target="_blank">Image by kjpargeter on Freepik</a></p>
        </div>
    </div>
  )
};


export default Header
