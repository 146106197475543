import React from 'react';
import './possibility.css';
import possibilityImage from '../../assets/possibility.png';
const Possibility = () => {
  return (
    <div className='gpt4__possibility section__padding' id="possibility">
      <div className='gpt4__possibility-image'>
      <img src={possibilityImage} alt="possibilityimage" />
      <p>Image by <a href="https://www.freepik.com/free-photo/medium-shot-man-wearing-vr-glasses_19265130.htm#&position=1&from_view=undefined">Freepik</a></p>
      </div>
      <div className="gpt4__possibility-content">
        <h4>Request Early Access to Get Started</h4>
        <h1 className='gradient__text'>The possibilities are beyond your imagination</h1>
        <p>As AI technology advances, GPT-4 has the potential to become even more sophisticated, with the ability to handle more complex tasks and interactions. Possible future applications include creating chatbots for customer service, enhancing virtual assistants, and improving language translation technology. Additionally, GPT-4 could be used for generating creative writing, assisting in research and development, and improving healthcare diagnostics. The possibilities are endless, and as GPT-4's capabilities continue to grow, it has the potential to revolutionize the way we work, communicate, and interact with technology.</p>
        <h4>Request Early Access to Get Started</h4>
      </div>
    </div>
  )
}

export default Possibility;
