import React from 'react';
import './whatgpt4.css';
import { Feature } from '../../components';

const Whatgpt4 = () => {
  return (
    <div className='gpt4__whatgpt4 section__margin' id="wgpt4">
      <div className='gpt4__whatgpt4-feature'>
        <Feature title='What is GPT-4' text="GPT-4 is a large-scale language model developed by OpenAI, capable of generating human-like responses in a conversational style. It performs a range of natural language processing tasks and has the potential to transform how we interact with machines and access information."/>
      </div>
      <div className='gpt4__whatgpt4-heading'>
        <h1 className='gradient__text'>The Possibility are beyond your imagination</h1>
        <p><a href='#blog'>Explore the Library</a></p>
      </div>
      <div className='gpt4__whatgpt4-container'>
        <Feature title="Chatbots" text=" Its natural language processing abilities and conversational flow enable it to communicate more effectively with users, while its ability to learn and personalize responses based on user preferences improves the user experience. "/>
        <Feature title="Knowledgebase" text="GPT-4's knowledge base is a large collection of text data that it has been trained on. It covers many topics and is continually updated, allowing GPT-4 to provide accurate responses to queries across different domains."/>
        <Feature title="Education" text="Providing personalized and interactive learning experiences. Its ability to generate text, engage in natural language conversations, and adapt to individual learning styles makes it a valuable virtual tutor for a wide range of subjects."/>
      </div>
    </div>
  )
}

export default Whatgpt4;
