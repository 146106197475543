import React from 'react';
import './App.css';
import { Blog,Features,Footer,Header,Possibility,Whatgpt4 } from './containers';
// The code above will Import the Containers from index.js file in the containers directory
import { CTA,Brand,Navbar } from './components';
// The code above will Import the Components from index.js file in the components directory

const App = () => {
    return (
        <div className='App'>
            <div className='gradient__bg'>
                <Navbar />
                <Header />
            </div>
            <Brand />
            <Whatgpt4 />
            <Features />
            <Possibility />
            <CTA />
            <Blog />
            <Footer />
        </div>
    )
}
export default App;